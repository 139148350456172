// French
export default{
    form: {
        startSurveyButton: "Commençons",
        nextButton: "Suivant",
        submitAnswerButton: "Soumettre la réponse",
        continueButton: "Continuer",
        finishButton: "Terminer",
        headerPercentageCompletion: 'complété',
        headerQuestionsCompleted: 'questions',
        headerDefaultErrorMessage: 'Oups. Un problème est survenu.',
        brandingPoweredBy: 'Propulsé par',
        brandingPromoWhySurvey: 'Pourquoi sonder quand vous pouvez <b>{product}</b>?',
        brandingPromoGetStarted: 'Commencez dès aujourd\'hui!',
        submissionPending: "Envoi de votre voiceform. Veuillez ne pas fermer la fenêtre.",
        submissionCanCloseWindow: "Votre voiceform a été soumis avec succès. Vous pouvez fermer la fenêtre maintenant 👍",
        mediaAttachmentOverlayCloseButton: "Fermer",
        expiredFormHeader: "Merci de votre visite!",
        expiredFormMessage: "Il semble que ce sondage ait expiré. Veuillez contacter le créateur du voiceform pour l'informer.",
        notPublishedFormHeader: "Le voiceform n'est pas publié!",
        notPublishedFormMessage: "Il semble que ce sondage ne soit pas publié. Veuillez contacter le créateur du voiceform pour l'informer.",
        notPublishedFormRetryButton: "Réessayer",
        errorFormHeader: "Oups!",
        errorFormMessage: "Un problème est peut-être survenu. Veuillez réessayer plus tard.",
        errorFormRetryButton: "Réessayer",
        emptyFormHeader: "Merci de votre visite!",
        emptyFormMessage: "Oups! Ce formulaire semble actuellement vide sans questions ou champs à remplir. Nous nous excusons pour le désagrément. Veuillez contacter le créateur du formulaire pour résoudre ce problème. Merci pour votre compréhension et coopération!",

        restoreFormHeader: "Il semble que vous ayez un formulaire en cours!",
        restoreFormMessage: "Voulez-vous reprendre là où vous vous étiez arrêté?",
        restoreFormButton: "Continuer le formulaire",
        restoreFormButtonNew: "Recommencer",

        audioPermissionHeader: "Voulez-vous répondre par la voix?",
        audioPermissionMessage: "Répondre à l'oral est rapide et facile.",
        audioPermissionAcceptButton: "Oui, je veux",
        audioPermissionDenyButton: "Non, merci",
        audioPermissionErrorHeader: "Micro non disponible",
        audioPermissionErrorMessage: "Veuillez vérifier les permissions de votre navigateur pour activer l'accès au micro.",
        audioPermissionErrorButton: "D'accord",
        audioRecorderFailedUpload: "Échec du téléchargement de l'enregistrement",
        silenceDetectorError: "On ne vous entend pas. Vérifiez les paramètres du micro. Astuce: cela peut être votre micro externe.",
        audioRecorderButtonRecord: "Enregistrer",
        audioRecorderButtonRecordMore: "Enregistrer plus",
        voiceResponseSelectionMessage: "Choisissez comment vous voulez répondre...",
        voiceResponseSelectionOrDivider: "ou",
        voiceResponseTextInputPlaceholder: "Tapez la réponse",
        voiceResponseTextInputCharacterCounterMinMessage: "Veuillez entrer au moins {min} caractères",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} caractères (minimum {min} caractères)",
        voiceResponseTextInputCharacterValidationMessage: "{count} caractères (minimum {min} caractères)",

        // checkbox
        checkboxValidationTooFew: "Vous devez sélectionner au moins {min} option(s)",
        checkboxValidationTooMany: "Vous devez sélectionner au maximum {max} option(s)",
        checkboxNoneOfTheAboveOption: "Aucune des réponses ci-dessus",
        checkboxInvalidAnswer: "Réponse invalide.",

        // datepicker
        datePickerPlaceholder: "Sélectionner la date",

        // dropdown
        // email
        emailLabel: "Email",
        emailInvalid: "E-mail invalide.",
        // file-upload
        fileUploadPluginNameCamera: "Caméra",
        fileUploadPluginNameCameraVideo: "Enregistrer une vidéo",
        fileUploadPluginScreenCast: "Partage d'écran",
        fileUploadVideoImportFilesDefault: 'Enregistrer ou téléverser une vidéo via:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Sélectionnez une option d\'enregistrement vidéo:',
        fileUploadVideoImportFilesNoCamera: 'Appuyez sur un bouton pour enregistrer une vidéo de l\'écran',
        fileUploadVideoImportFilesNoScreenCast: 'Appuyez sur un bouton pour enregistrer une vidéo',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Aucune option disponible pour enregistrer une vidéo',

        // matrix
        matrixValidationMessage: "La réponse est valide.",
        matrixRow: "Ligne",
        matrixColumn: "Colonne",
        matrixRowRequired: "La ligne {rowTitle} est obligatoire.",
        matrixRadioGroup: "Groupe radio",
        matrixCheckboxGroup: "Groupe de cases à cocher",
        matrixGroupRequired: "Pour la ligne {rowTitle}. {type} {groupTitle} est requis.",
        matrixColumnRequired: "Pour la ligne {rowTitle}. La colonne {columnTitle} est obligatoire.",
        matrixColumnInvalid: "Pour la ligne {rowTitle}. La colonne {columnTitle} est invalide.",
        matrixRequired: "Obligatoire.",
        matrixNumericMustBeNumber: "Doit être un nombre.",
        matrixNumericMustBeGreaterThenMin: "Doit être supérieur à {min}.",
        matrixNumericMustBeLessThenMax: "Doit être inférieur à {max}.",
        matrixNumericMustBeInteger: "Doit être un entier.",
        matrixNumericInvalidNumber: "Nombre invalide.",

        // name
        nameLabel: "Nom",
        nameInvalid: "Précisez le prénom et le nom.",

        // nps
        npsNotLikely: "Pas du tout probable",
        npsExtremelyLikely: "Extrêmement probable",

        // numeric input
        numericInputRequired: "Obligatoire.",
        numericInputMustBeNumber: "Doit être un nombre.",
        numericInputMustBeGreaterThenMin: "Doit être supérieur à {min}.",
        numericInputMustBeLessThenMax: "Doit être inférieur à {max}.",
        numericInputMustBeInteger: "Doit être un entier.",
        numericInputInvalidNumber: "Nombre invalide.",
        numericInputPlaceholder: "Tapez un nombre",

        // phone
        phoneInvalid: "Le numéro de téléphone est invalide.",
        phoneCountrySelectorLabel: 'Indicatif du pays',
        phoneCountrySelectorError: 'Choisissez le pays',
        phoneNumberLabel: 'Numéro de téléphone',
        phoneExample: 'Exemple:',

        // boolean
        booleanYesLabel: "Oui",
        booleanNoLabel: "Non",

        //questionStep
        questionStepAudioQuestionLabel: "Question audio",

        // errors
        invalidPhoneNumber: "{phone} est un numéro de téléphone invalide.",
        invalidEmail: "{email} est une adresse e-mail invalide.",
        questionIsRequired: "La question est obligatoire.",
        answerIsNotValid: "La réponse n'est pas valide.",
        unfinishedProbingDialogError: "Veuillez terminer le dialogue.",
        cannotResumePartialResponse: "Impossible de reprendre la réponse partielle.",
        failedToSubmitForm: "Échec de l'envoi du formulaire. Veuillez vérifier votre connexion internet et réessayer.",

        //language picker
        searchLanguage: "Rechercher une langue",
    }
}
